
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../modules/auth';
import { useReactToPrint } from 'react-to-print';
import { FormikValues } from 'formik'
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import { orders_get_all, orders_view_details, orders_update } from '../../modules/auth/core/_requests';
import Orders_Print from './Orders_Print';
import Orders_Table from './Orders_Table';
import Orders_View from './Orders_View';
const socket = io("https://app.digitalfactoryindia.com:3001");

interface ordersData {
    order_id: string;
    contact_number: string;
    contact_person: string;
    order_total: string;
    order_status: string;
    delivery_type: string;
    expected_date: string;
    expected_time: string;
    order_completed_on: string;
    order_on: string;
    order_mode: string;
    order_payment_status: any;
}

interface Orderitems {
    order_id: string;
    order_item_name: string;
    order_item_qty: string;
    order_item_price: string;
    order_item_inline_total: string;
}

interface OrderDetails {
    order_id: string;
    contact_number: string;
    contact_person: string;
    delivery_type: string;
    expected_date: string;
    expected_time: string;
    order_completed_by: string;
    order_completed_on: string;
    order_total: string;
    order_status: string;
    order_on: string;
    special_instructions: string;
    message: string;
    order_items: Orderitems[];
    order_mode: string;
    payment_status: number;
    gst: string;
    address: string;
}

const Orders = () => {
    const { order_status } = useParams<{ order_status: string }>();
    const { currentUser } = useAuth()
    const [userid] = useState(currentUser?.user_id as number) || 0
    const [isLoading, setIsLoading] = useState(true);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [loading, setLoading] = useState(false)
    const [showPerPage, setShowPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [orders, setOrders] = useState<ordersData[]>([]);
    const [viewOrders, setViewOrders] = useState<OrderDetails>()
    const [currentPage, setCurrentPage] = useState(1);
    const [filtered_orders, setFiltered_orders] = useState<ordersData[]>([]);
    const [showEditModal, setShowEditModal] = useState(false)
    const [initialEditValues, setInitialEditValues] = useState(
        {
            order_upd_id: '',
            order_upd_status: '',
            order_upd_notes: ''
        }
    )
    const [filterStatus, setFilterStatus] = useState(order_status)

    const FetchOrdesData = async (orders: any) => {
        try {
            const response = await orders_get_all(orders);
            setOrders(response.data.order_details);
            setIsLoading(false);
            socket.emit('ORDERS_INITAL_DATA', response.data.order_details);
            socket.on("orders", (data: any) => {
                setOrders(data);
            })
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        FetchOrdesData("0");
    }, []);

    const handleViewOrders = async (order_id: any) => { 
        setDetailsLoading(true) 
        try {
            setLoadingEdit(true)
            const response = await orders_view_details(order_id);
            setViewOrders(response.data);
            const initialData = response.data
            setInitialEditValues({
                order_upd_id: initialData.order_id,
                order_upd_status: initialData.order_status,
                order_upd_notes: '',
            });
            setShowEditModal(true);
            setDetailsLoading(false); 
        } catch (error) {
            console.error('Error fetching members:', error);
        } finally {
            setLoadingEdit(false);
          }
    };

    const handleUpdateOrders = async (values: any, actions: FormikValues) => {
        try {
            setLoading(true)
            const ordersData = new FormData();
            const response = await orders_update(
                ordersData,
                values.order_upd_id,
                values.order_upd_status,
                values.order_upd_notes,
                userid
            )
            actions.resetForm();
            setShowEditModal(false)
            setTimeout(() => {
                setLoading(false)
                FetchOrdesData("0");
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    }

    useEffect(() => {
        const filtered = orders?.filter((item: any) => {
            const fullName = `${item.order_id} ${item.contact_person}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
        setFiltered_orders(filtered);
    }, [searchQuery, orders]);

    const handleFilterChange = (e: any) => {
        setFilterStatus(e.target.value);
    };

    useEffect(() => { 
        const filteredOrders = orders.filter((item) => {
            if (!filterStatus) {
                return true;
            }
            return item.order_status === filterStatus;
        });
        setFiltered_orders(filteredOrders);
    }, [filterStatus, orders]);

    const handleItemsPerPageChange = (perPage: number) => {
        setShowPerPage(perPage);
        setCurrentPage(1);
    };

    const itemsPerPage = 10;
    const totalItems = filtered_orders.length;
    const filterDataTotal = filtered_orders.length
    const totalPages = Math.ceil(totalItems / showPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
    const startIndex = (currentPage - 1) * showPerPage;
    const endIndex = startIndex + showPerPage;
    const total_orders = filtered_orders.slice(startIndex, endIndex);

    const printRef = useRef<HTMLDivElement | null>(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <>
            <div className='card'>
                <Orders_Table orders={orders} handleFilterChange={handleFilterChange} filterStatus={filterStatus} searchQuery={searchQuery} setSearchQuery={setSearchQuery}
                    isLoading={isLoading} total_orders={total_orders} handleViewOrders={handleViewOrders} currentPage={currentPage} filterTotalPage={filterTotalPage}
                    itemsPerPage={itemsPerPage} setCurrentPage={setCurrentPage} handleItemsPerPageChange={handleItemsPerPageChange} totalPages={totalPages} showPerPage={showPerPage}
                />
            </div>

            {showEditModal && (
              <Orders_View loadingEdit={loadingEdit} setShowEditModal={setShowEditModal} handlePrint={handlePrint} initialEditValues={initialEditValues} handleUpdateOrders={handleUpdateOrders}
              viewOrders={viewOrders} detailsLoading={detailsLoading} loading={loading}
              />
            )}

            <div ref={printRef} className="my-custom-div">
                <Orders_Print viewOrders={viewOrders} />
            </div>
        </>
    )
}
export default Orders