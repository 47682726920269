import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import PaginationNew from '../PaginationNew'

const Orders_Table = ({ orders, handleFilterChange, filterStatus, searchQuery, setSearchQuery, isLoading,
    total_orders, handleViewOrders, currentPage, filterTotalPage, itemsPerPage, setCurrentPage, handleItemsPerPageChange, totalPages, showPerPage
}: any) => {
    return (
        <>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Orders</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Total {orders.length} orders</span>
                </h3>
                <div className='card-toolbar'>
                    <div>
                        <select
                            className='form-select form-select-solid'
                            onChange={handleFilterChange}
                            value={filterStatus}
                        >
                            <option value="">Order Status</option>
                            <option value="0">Pending</option>
                            <option value="1">Processing</option>
                            <option value="2">Rejected</option>
                            <option value="3">Completed</option>
                            <option value="4">Out For Delivery</option>
                            <option value="5">Getting Ready</option>
                            <option value="6">Order is Ready</option>
                        </select>
                    </div> 
                    <div className="d-flex align-items-center ms-5">
                        <div className="input-group">
                            <input type="search" placeholder='Search Order ID & Name...' className="form-control w-50"
                                value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button type="button" className="btn btn-primary">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-body py-3'>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (total_orders.length > 0 ? (
                    <div>
                        <table className='table align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold fs-6 bg-light'>
                                    <th className='ps-4 rounded-start text-center'>Order Id</th>
                                    <th > Status</th>
                                    <th > Name</th>
                                    <th > Number</th>
                                    <th >Type</th>
                                    <th >Expected On</th>
                                    <th >Delivered On</th>
                                    <th >Payment</th>
                                    <th > Total</th>
                                    <th >Order On</th>
                                    <th className=' pe-4 rounded-end'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {total_orders.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td className='text-dark fw-bold text-hover-primary fs-6 ps-4'>
                                            {item.order_id}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {item.order_status === "1" ? (
                                                <span className='badge bg-warning text-white'>Processing</span>
                                            ) : item.order_status === "0" ? (
                                                <span className='badge bg-danger text-white'>Pending</span>
                                            ) : item.order_status === "3" ? (
                                                <span className="badge bg-success text-white">Completed</span>
                                            ) : item.order_status === "2" ? (
                                                <span className='badge bg-info text-white'>Rejected</span>
                                            ) : item.order_status === "4" ? (
                                                <span className='badge bg-info text-white'>Out For Delivery</span>
                                            ) : item.order_status === "5" ? (
                                                <span className='badge bg-info text-white'>Getting Ready</span>
                                            ) : item.order_status === "6" ? (
                                                <span className='badge bg-info text-white'>Order is Ready</span>
                                            ) : null}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {item.contact_person ? item.contact_person : "-"}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {item.contact_number}
                                        </td>
                                        <td className='text-dark fw-bold fs-6'>
                                            <span>
                                                {item?.delivery_type === 'pickup' || item?.delivery_type === 'Pickup' ? (
                                                    <span className='badge bg-info text-white fs-9 fw-bold'>
                                                        <KTIcon iconName='scooter' className='fs-3 me-1 text-white' />
                                                        Pickup
                                                    </span>
                                                ) : item?.delivery_type === 'delivery' || item?.delivery_type === 'Delivery' ? (
                                                    <span className='badge bg-primary text-white fs-9 fw-bold'>
                                                        <KTIcon iconName='delivery-time' className='fs-3 me-1 text-white' />
                                                        Home Delivery
                                                    </span>
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-7'>
                                            {item?.expected_date? item?.expected_date : "-"} <br />{item?.expected_time}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {item?.order_completed_on ? item.order_completed_on : '-'}
                                        </td>
                                        <th > {item.order_payment_status === "1" ? (
                                            <span className='badge bg-success text-white fs-9 fw-bold'>Paid</span>
                                        ) : (
                                            <span className='badge bg-danger text-white fs-9 fw-bold'>Not Paid</span>
                                        )}</th>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            ₹{item.order_total}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {item.order_on}
                                        </td>
                                        <td style={{ paddingTop: "10px" }}>
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots fs-5"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <li>
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={() => handleViewOrders(item.order_id)}
                                                        >
                                                            View Order
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {searchQuery ? (
                            <PaginationNew
                                currentPage={currentPage}
                                totalPages={filterTotalPage}
                                itemsPerPage={itemsPerPage}
                                handlePageChange={setCurrentPage}
                                handleItemsPerPageChange={handleItemsPerPageChange}
                            />
                        ) : (
                            <PaginationNew
                                currentPage={currentPage}
                                totalPages={totalPages}
                                itemsPerPage={showPerPage}
                                handlePageChange={setCurrentPage}
                                handleItemsPerPageChange={handleItemsPerPageChange}
                            />
                        )}
                    </div>
                ) :
                    <div className="d-flex justify-content-center p-10">
                        <div className="car">
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                            <h3 className="text-center">No orders found</h3>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Orders_Table