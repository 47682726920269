import React, { useState } from 'react'
import { getUserByToken, login } from '../core/_requests'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAuth } from '../core/Auth'
import * as Yup from 'yup';
import Swal from 'sweetalert2'

const LoginForm = () => {
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()

    const initialValues = {
        user_phone_number: '',
        user_password: '',
    }

    const validationSchema = Yup.object().shape({
        user_phone_number: Yup.string()
          .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
          .required('Mobile number is required'),
          user_password: Yup.string()
          .min(3, 'Minimum 3 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Password is required'),
      })

    const handleSubmit = async (values: any) => {
        setLoading(true)
        try {
            const response: any = await login(values.user_phone_number, values.user_password)
            if (response.data === 'Failed') {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please Check Email Password',
                });
            } else {
                saveAuth(response.data)
            }
            const loginStatus = response.data.api_token;
            if (loginStatus) {
                const { data: user } = await getUserByToken(response.data.api_token)
                setCurrentUser(user)
            } else {
                saveAuth(undefined)
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
            saveAuth(undefined)
            setLoading(false)
        }
    }
    return (
        <>
            <div className='w-75'>
                <div className='text-center mt-10'>
                    <h4 className='fs-2x fw-bold'>Welcome Back</h4>
                    <p className='fw-bold'>Sign in to your Account</p>
                </div>
                <div className='mt-10'>
                    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                        {() => (
                            <Form>
                                <div className="mb-10">
                                    <label className="form-label mb-3 fw-bolder text-dark" htmlFor="user_phone_number" >Enter Phone Number</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="far fa-user"></i>
                                        </span>
                                        <Field type="text" name="user_phone_number" placeholder="Enter Phone Number" className="form-control" />
                                    </div>
                                    <ErrorMessage name="user_phone_number" component="div" className="text-danger" />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="user_password" className='form-label fw-bolder text-dark mb-3'>Password</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-lock"></i>
                                        </span>
                                        <Field type="password" name="user_password" placeholder="Enter Password" className="form-control" />
                                    </div>
                                    <ErrorMessage name="user_password" component="div" className="text-danger" />
                                </div>

                                <div className='mb-8 mt-12'>
                                    <button
                                        type='submit'
                                        id='kt_sign_in_submit'
                                        className='btn btn-success login_btn'
                                    >
                                        {!loading && <span className='indicator-label'>Login</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>

                                <div className="text-dark text-center fw-semibold fs-6">
                                    Built & Powered by <a href="https://digitalfactoryindia.com/" className='text-primary' target="_new">DIGITAL FACTORY</a>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div >
        </>
    )
}

export default LoginForm